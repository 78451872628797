import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import FeatherIcon from 'feather-icons-react';

// Import components - these would be in separate files
import SectionTitle from '../../components/Shared/SectionTitle';

// Import images - these would be imported from your assets
import startupSVG from '../../assets/images/illustrator/Startup_SVG.svg';
import LostTime from "../../assets/images/landing/lost-time.png";
import EngineerTeamImage from "../../assets/images/company/about2.png";
import TestimonialAvatar from "../../assets/images/customer/customer.png";
import {getMetaCookies, sendMetaPixelEvent} from "../../components/Shared/services/MetaApiService";
import PhoneWithCountryCode from "../../components/Shared/PhoneWithCountryCode";
import {automationsPixelId} from "../../common/data/landing";

// Video embed component
const VideoEmbed = () => {
    return (
        <div className="position-relative">
            <div className="ratio ratio-16x9 shadow rounded">
                <iframe
                    src="https://www.youtube.com/embed/your-video-id"
                    title="Innovatex Presentation"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
};

// Case study card component
const CaseStudyCard = ({icon, title, description, className = ""}) => {
    return (
        <Card className={`feature-card shadow rounded border-0 ${className}`}>
            <CardBody className="p-4">
                <div className="d-flex align-items-center">
                    <div className="icon text-center rounded-circle bg-primary bg-soft me-3">
                        <i className={`uil ${icon} text-primary h3 mb-0`}></i>
                    </div>
                    <div className="flex-1">
                        <h5 className="mb-1">{title}</h5>
                        <p className="text-muted mb-0">{description}</p>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

const InnovatexLanding = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        sector: '',
        isDecisionMaker: false,
        budget: '',
        message: ''
    });

    const [submitting, setSubmitting] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [formError, setFormError] = useState(null);

    useEffect(() => {
        sendMetaPixelEvent('PageView', getMetaCookies(), automationsPixelId);

        // Add scroll behavior for navbar
        const scrollNavigation = () => {
            const doc = document.documentElement;
            const navBar = document.getElementById("topnav");
            const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
            if (navBar) {
                if (top > 80) {
                    navBar.classList.add("nav-sticky");
                } else {
                    navBar.classList.remove("nav-sticky");
                    document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
                    document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
                    document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
                }
            }
        };

        window.addEventListener("scroll", scrollNavigation, true);

        return () => {
            window.removeEventListener("scroll", scrollNavigation, true);
        };
    }, []);

    const handleInputChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);

        const budgetMap = {
            "menos-500": 500,
            "500-1000": 500,
            "1000-3000": 1000,
            "3000-5000": 3000,
            "mas-5000": 5000
        };

        const submissionData = {
            ...formData,
            budget: budgetMap[formData.budget] || 0, // Default to 0 if no match
            metadata: getMetaCookies()
        };

        try {
            // Track form submission with Facebook Pixel
            window.fbq('track', 'Lead');

            // Call the n8n webhook for processing form data
            const response = await fetch('https://vm-gcp.n8n.innovatexiot.com/webhook/4beb5e2e-8dea-4737-8874-9f9abb3ba1d2/form-contact/landing-innovatexiot', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(submissionData),
            });

            const data = await response.json();

            if (response.ok) {
                setFormSuccess(true);
                setFormError(null);
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    company: '',
                    sector: '',
                    isDecisionMaker: false,
                    budget: '',
                    message: ''
                });
            } else {
                setFormError(data.message || 'Hubo un error al enviar el formulario. Por favor intenta de nuevo.');
                setFormSuccess(false);
            }
        } catch (error) {
            setFormError('Error en la conexión. Por favor intenta de nuevo más tarde.');
            setFormSuccess(false);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <React.Fragment>
            {/* Hero Section */}
            <section className="bg-half-170 pb-0 d-table w-100" id="home">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={7} md={7} className="order-2 order-md-1">
                            <div className="title-heading mt-4 ms-lg-4">
                                <h1 className="heading mb-3">
                                    Automatiza o Reviéntate <br/>
                                    <span className="text-primary">Haz que tu empresa trabaje sola (literalmente)</span>
                                </h1>
                                <p className="para-desc text-muted">
                                    Creamos automatizaciones y asistentes inteligentes personalizados para tu negocio.
                                    Ahorra horas, escala sin contratar más personal y gana libertad.
                                    <span className="text-primary fw-bold"> Diagnóstico gratis</span> en 20 minutos.
                                </p>
                                <div className="mt-4 pt-2">
                                    <a href="#schedule" className="btn btn-primary">
                                        Agendar diagnóstico gratuito <i className="uil uil-arrow-right ms-1"></i>
                                    </a>
                                </div>
                                <div className="features-list d-flex flex-wrap mt-4 pt-2">
                                    <div className="feature-item d-flex align-items-center me-4 mb-3">
                                        <div className="feature-icon bg-soft-primary rounded-circle me-2">
                                            <i className="uil uil-check-circle text-primary"></i>
                                        </div>
                                        <span className="text-muted">100% personalizado</span>
                                    </div>
                                    <div className="feature-item d-flex align-items-center me-4 mb-3">
                                        <div className="feature-icon bg-soft-primary rounded-circle me-2">
                                            <i className="uil uil-check-circle text-primary"></i>
                                        </div>
                                        <span className="text-muted">Implementación en 15 días</span>
                                    </div>
                                    <div className="feature-item d-flex align-items-center mb-3">
                                        <div className="feature-icon bg-soft-primary rounded-circle me-2">
                                            <i className="uil uil-check-circle text-primary"></i>
                                        </div>
                                        <span className="text-muted">Garantía de resultados</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={5} md={5} className="order-1 order-md-2">
                            <div className="position-relative">
                                <img src={startupSVG} className="img-fluid" alt="Automatizaciones Innovatex"/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Pain and Benefit Section */}
            <section className="section bg-light">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={5} md={6}>
                            <img src={LostTime} className="img-fluid" alt="Ahorra tiempo con automatizaciones"/>
                        </Col>
                        <Col lg={7} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title ms-lg-4">
                                <h4 className="title mb-4">¿Cuánto te cuesta seguir haciendo todo a mano?</h4>
                                <p className="text-muted">
                                    Cada hora que tu equipo pierde en tareas repetitivas es dinero que se esfuma.
                                    Responder mensajes, coordinar entregas, hacer seguimientos, enviar reportes...
                                    Todo eso se puede automatizar. Y cuando lo haces, <span className="fw-bold">tu negocio se vuelve más rentable, más rápido y más libre.</span>
                                </p>
                                <p className="text-muted">
                                    En Innovatex, te ayudamos a <span className="fw-bold">recuperar esas horas y transformarlas en crecimiento.</span>
                                </p>
                                <div className="mt-4">
                                    <a href="#video" className="text-primary h6">
                                        Ver cómo lo hacemos <i className="uil uil-angle-right-b align-middle"></i>
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Video Section */}
            <section className="section" id="video">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} className="text-center">
                            <SectionTitle
                                title="Conoce cómo trabajamos"
                                desc="Un vistazo a nuestro proceso y la tecnología detrás de nuestras automatizaciones."
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={10} md={12}>
                            <VideoEmbed/>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Offer Section */}
            <section className="section bg-primary bg-gradient">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8} md={10} className="text-center text-white">
                            <h2 className="mb-4">Implementamos automatizaciones personalizadas en 15 días o te
                                devolvemos tu dinero.</h2>
                            <p className="text-white-50">
                                Diseñamos soluciones a tu medida con tecnología de automatización e inteligencia
                                artificial
                                (n8n, APIs, integraciones). Podemos automatizar desde el seguimiento de ventas
                                hasta la atención al cliente por WhatsApp o Instagram.
                            </p>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-4">
                        <Col lg={10}>
                            <Row>
                                <Col md={4} className="mt-4">
                                    <div className="text-center text-white">
                                        <div
                                            className="rounded-circle bg-white text-primary d-flex align-items-center justify-content-center mx-auto"
                                            style={{width: '80px', height: '80px'}}>
                                            <FeatherIcon icon="search" size={38}/>
                                        </div>
                                        <h5 className="mt-4 text-white">Diagnóstico gratuito</h5>
                                        <p className="text-white-50">Analizamos tu negocio para encontrar oportunidades
                                            de automatización.</p>
                                    </div>
                                </Col>
                                <Col md={4} className="mt-4">
                                    <div className="text-center text-white">
                                        <div
                                            className="rounded-circle bg-white text-primary d-flex align-items-center justify-content-center mx-auto"
                                            style={{width: '80px', height: '80px'}}>
                                            <FeatherIcon icon="cpu" size={38}/>
                                        </div>
                                        <h5 className="mt-4 text-white">Implementación personalizada</h5>
                                        <p className="text-white-50">Desarrollamos la solución exacta para tus
                                            necesidades específicas.</p>
                                    </div>
                                </Col>
                                <Col md={4} className="mt-4">
                                    <div className="text-center text-white">
                                        <div
                                            className="rounded-circle bg-white text-primary d-flex align-items-center justify-content-center mx-auto"
                                            style={{width: '80px', height: '80px'}}>
                                            <FeatherIcon icon="book-open" size={38}/>
                                        </div>
                                        <h5 className="mt-4 text-white">Capacitación a tu equipo</h5>
                                        <p className="text-white-50">Te enseñamos a aprovechar al máximo las nuevas
                                            automatizaciones.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-4 pt-2">
                        <Col xs={12} className="text-center">
                            <a href="#schedule" className="btn btn-light">Quiero automatizar mi negocio</a>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Why Innovatex Section */}
            <section className="section">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={7} md={6}>
                            <div className="section-title">
                                <h4 className="title mb-4">Un bufete de ingenieros, no una agencia genérica</h4>
                                <p className="text-muted">
                                    Somos una <span className="fw-bold">asociación de desarrolladores senior</span> con
                                    experiencia real
                                    en banca, retail, IoT y startups. A diferencia de una agencia tradicional, en
                                    Innovatex cada cliente
                                    es atendido por el ingeniero ideal, y cuando el reto lo requiere, <span
                                    className="fw-bold">formamos
                  equipos de alto nivel para resolverlo como una célula especializada</span>.
                                </p>
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                                        Soluciones a medida, no enlatadas
                                    </li>
                                    <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                                        Escalamos contigo según el tamaño de tu proyecto
                                    </li>
                                    <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                                        Hablamos tu idioma (negocio + tecnología)
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={5} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <img src={EngineerTeamImage} className="img-fluid" alt="Equipo de ingenieros de Innovatex"/>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Use Cases Section */}
            <section className="section bg-light">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} className="text-center">
                            <SectionTitle
                                title="¿Y qué podemos automatizar?"
                                desc="Soluciones adaptadas a diferentes tipos de negocios"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={6} className="mt-4 pt-2">
                            <CaseStudyCard
                                icon="uil-store"
                                title="Ecommerce"
                                description="Seguimiento de pedidos, notificaciones de pago, abandono de carrito, recomendaciones personalizadas"
                            />
                        </Col>
                        <Col lg={6} md={6} className="mt-4 pt-2">
                            <CaseStudyCard
                                icon="uil-analysis"
                                title="Agencias"
                                description="Generación de leads, asignación de tareas, reporting automático, seguimiento de proyectos"
                            />
                        </Col>
                        <Col lg={6} md={6} className="mt-4 pt-2">
                            <CaseStudyCard
                                icon="uil-shop"
                                title="Tiendas físicas"
                                description="Agenda de citas, recordatorios, mensajes por WhatsApp, fidelización de clientes"
                            />
                        </Col>
                        <Col lg={6} md={6} className="mt-4 pt-2">
                            <CaseStudyCard
                                icon="uil-robot"
                                title="Cualquier empresa"
                                description="Asistentes inteligentes, dashboards, integración de sistemas, automatización de procesos"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Testimonial Section */}
            <section className="section">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8} md={10}>
                            <Card className="rounded shadow border-0">
                                <CardBody className="p-5">
                                    <div className="d-flex">
                                        <i className="uil uil-quote-left text-primary display-2 me-3"></i>
                                        <div>
                                            <h5 className="mb-4">
                                                "Nos ahorramos al menos 15 horas a la semana en tareas manuales, y ahora
                                                el equipo se enfoca
                                                en vender, no en perseguir pendientes. No sabía que se podía hacer todo
                                                esto sin cambiar de sistema."
                                            </h5>
                                            <div className="d-flex align-items-center">
                                                <img src={TestimonialAvatar}
                                                     className="avatar avatar-small rounded-circle"
                                                     alt="Testimonio de cliente"/>
                                                <div className="ms-3">
                                                    <h6 className="mb-0">John Walter Mur.</h6>
                                                    <small className="text-muted">CEO drogas LA POLA</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Final CTA and Scheduling Form */}
            <section className="section bg-light" id="schedule">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8} md={10} className="text-center">
                            <h2 className="mb-4">Tu empresa no necesita más gente.<br/>Necesita más inteligencia.</h2>
                            <p className="text-muted para-desc mx-auto">
                                Agendemos una llamada gratuita de 20 minutos donde te muestro cómo podrías automatizar
                                lo que hoy te está desgastando. Sin compromiso, sin tecnicismos. Solo resultados.
                            </p>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-4">
                        <Col lg={8} md={10}>
                            <Card className="rounded shadow border-0">
                                <CardBody className="p-4">
                                    {formSuccess ? (
                                        <Alert color="success">
                                            ¡Gracias por contactarnos! En breve nos comunicaremos contigo para agendar
                                            tu diagnóstico gratuito.
                                        </Alert>
                                    ) : (
                                        <Form onSubmit={handleFormSubmit}>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="name">Nombre completo *</Label>
                                                        <div className="position-relative">
                                                            <i className="uil uil-user position-absolute ms-3"
                                                               style={{top: '11px'}}></i>
                                                            <Input
                                                                type="text"
                                                                className="form-control ps-5"
                                                                id="name"
                                                                name="name"
                                                                placeholder="Tu nombre"
                                                                required
                                                                value={formData.name}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="email">Correo electrónico *</Label>
                                                        <div className="position-relative">
                                                            <i className="uil uil-envelope position-absolute ms-3"
                                                               style={{top: '11px'}}></i>
                                                            <Input
                                                                type="email"
                                                                className="form-control ps-5"
                                                                id="email"
                                                                name="email"
                                                                placeholder="tu@email.com"
                                                                required
                                                                value={formData.email}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <PhoneWithCountryCode
                                                        value={formData.phone}
                                                        onChange={handleInputChange}
                                                        required={true}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="company">Empresa *</Label>
                                                        <div className="position-relative">
                                                            <i className="uil uil-building position-absolute ms-3"
                                                               style={{top: '11px'}}></i>
                                                            <Input
                                                                type="text"
                                                                className="form-control ps-5"
                                                                id="company"
                                                                name="company"
                                                                placeholder="Nombre de tu empresa"
                                                                required
                                                                value={formData.company}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="sector">Sector de tu negocio *</Label>
                                                        <div className="position-relative">
                                                            <i className="uil uil-briefcase position-absolute ms-3"
                                                               style={{top: '11px'}}></i>
                                                            <Input
                                                                type="select"
                                                                className="form-select ps-5"
                                                                id="sector"
                                                                name="sector"
                                                                required
                                                                value={formData.sector}
                                                                onChange={handleInputChange}
                                                            >
                                                                <option value="">Selecciona una opción</option>
                                                                <option value="ecommerce">Ecommerce / Tienda en línea
                                                                </option>
                                                                <option value="retail">Retail / Tienda física</option>
                                                                <option value="agency">Agencia de marketing/publicidad
                                                                </option>
                                                                <option value="services">Servicios profesionales
                                                                </option>
                                                                <option value="manufacturing">Manufactura / Producción
                                                                </option>
                                                                <option value="healthcare">Salud / Bienestar</option>
                                                                <option value="education">Educación / Formación</option>
                                                                <option value="technology">Tecnología / Software
                                                                </option>
                                                                <option value="other">Otro</option>
                                                            </Input>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="budget">Presupuesto mensual para automatización
                                                            *</Label>
                                                        <div className="position-relative">
                                                            <i className="uil uil-dollar-sign position-absolute ms-3"
                                                               style={{top: '11px'}}></i>
                                                            <Input
                                                                type="select"
                                                                className="form-select ps-5"
                                                                id="budget"
                                                                name="budget"
                                                                required
                                                                value={formData.budget}
                                                                onChange={handleInputChange}
                                                            >
                                                                <option value="">Selecciona una opción</option>
                                                                <option value="menos-500">Menos de $500</option>
                                                                <option value="500-1000">$500 - $1,000</option>
                                                                <option value="1000-3000">$1,000 - $3,000</option>
                                                                <option value="3000-5000">$3,000 - $5,000</option>
                                                                <option value="mas-5000">Más de $5,000</option>
                                                            </Input>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <Label for="message">¿Hay algo más que quieras
                                                            comentarnos?</Label>
                                                        <div className="position-relative">
                                                            <i className="uil uil-comment-alt-lines position-absolute ms-3"
                                                               style={{top: '11px'}}></i>
                                                            <Input
                                                                type="textarea"
                                                                className="form-control ps-5"
                                                                id="message"
                                                                name="message"
                                                                placeholder="Cuéntanos un poco más sobre tu necesidad de automatización..."
                                                                rows="4"
                                                                value={formData.description}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <FormGroup check className="mt-3">
                                                        <Input
                                                            type="checkbox"
                                                            id="isDecisionMaker"
                                                            name="isDecisionMaker"
                                                            checked={formData.isDecisionMaker}
                                                            onChange={handleInputChange}
                                                        />
                                                        <Label check for="isDecisionMaker">
                                                            Soy la persona que toma decisiones sobre este tema en mi
                                                            empresa
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                {formError && (
                                                    <Col md={12}>
                                                        <Alert color="danger" className="mt-3">
                                                            {formError}
                                                        </Alert>
                                                    </Col>
                                                )}
                                                <Col md={12} className="text-center mt-4">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="btn-block"
                                                        disabled={submitting}
                                                    >
                                                        {submitting ? 'Enviando...' : 'Agendar mi diagnóstico gratuito'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Facebook Pixel Tracking Code */}
            <noscript>
                <img height="1" width="1" className="d-none"
                     src={`https://www.facebook.com/tr?id=${automationsPixelId}&ev=PageView&noscript=1`}/>
            </noscript>
        </React.Fragment>
    );
};

export default InnovatexLanding;
