import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const PhoneWithCountryCode = ({ value, onChange, required = false }) => {
    const handlePhoneChange = (phoneValue) => {
        // Pass the phone value in the format expected by the parent component
        onChange({
            target: {
                name: 'phone',
                value: phoneValue || ''
            }
        });
    };

    // Custom styles to match the application's design
    const customStyles = {
        containerStyle: {
            position: 'relative'
        }
    };

    return (
        <FormGroup>
            <Label>Teléfono {required && '*'}</Label>
            <div style={customStyles.containerStyle}>
                <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="CO"
                    value={value}
                    onChange={handlePhoneChange}
                    className="form-control"
                    required={required}
                    placeholder="Tu número de teléfono"
                />
            </div>
        </FormGroup>
    );
};

export default PhoneWithCountryCode;
